import { graphql } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        defaultClass?: string
      }
    }
  }
}

// markup
const DefaultTemplate = (props: Props) => {
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className={props.data.page.frontmatter.defaultClass || ""}
    >
      <div className="container">
        <div className="content py-6">
          <HTMLContent
            content={props.data.page.html}
            className={"markdown"}
          ></HTMLContent>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default DefaultTemplate

// graphQL queries
export const pageQuery = graphql`
  query DefaultTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        defaultClass
      }
    }
  }
`
